import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { slotToTime } from '../../../utils/converters';

const Timeslots = ({ selectedDate, slotData, guests, setSelectedSlot, language }) => {
  const [localSelectedSlot, setLocalSelectedSlot] = useState(null);
  const [selectedInterval, setSelectedInterval] = useState(null);
  const [availableIntervals, setAvailableIntervals] = useState([]);
  const [displayedIntervals, setDisplayedIntervals] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState([]);

  useEffect(() => {
    if (window.shift) {
      setShiftData(window.shift);
    } else {
      setShiftData([]);
    }
  }, []);

  useEffect(() => {
    setSelectedInterval(null);
    if (selectedDate) {
      updateAvailableIntervals();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (availableIntervals.length > 0) {
      const checkIntervals = availableIntervals.filter(interval => {
        const filteredSlots = filterSlotsByInterval(interval);
        return filteredSlots.length > 0;
      });
      setDisplayedIntervals(checkIntervals);
    }
  }, [availableIntervals]);

  const shiftLength = window.shiftLength || 4;

  const updateAvailableIntervals = () => {
    const formattedDate = formatDateToGMT2(selectedDate);
    const timeslots = slotData[formattedDate] || {};

    const availableTimeslots = Object.keys(timeslots)
      .filter((slot) => {
        const isToday = isSameDay(selectedDate, new Date());
        const isSlotValid = timeslots[slot] >= guests;
        if (isToday) {
          const slotTime = slotToDate(slot);
          const currentTimePlus4Hours = new Date();
          currentTimePlus4Hours.setHours(currentTimePlus4Hours.getHours() + shiftLength);
          return isSlotValid && slotTime >= currentTimePlus4Hours;
        }
        return isSlotValid;
      })
      .map(Number);

    const checkConsecutiveSlots = (startSlot) => {
      for (let i = 0; i < shiftLength; i++) {
        if (!availableTimeslots.includes(startSlot + i)) {
          return false;
        }
      }
      return true;
    };

    const intervals = {
      Night: availableTimeslots.some(slot => slot >= 1 && slot < 14 && checkConsecutiveSlots(slot)),
      Morning: availableTimeslots.some(slot => slot >= 14 && slot < 25 && checkConsecutiveSlots(slot)),
      Afternoon: availableTimeslots.some(slot => slot >= 25 && slot < 34 && checkConsecutiveSlots(slot)),
      Evening: availableTimeslots.some(slot => slot >= 34 && slot <= 47 && checkConsecutiveSlots(slot)),
    };

    const filteredIntervals = Object.keys(intervals).filter(interval => intervals[interval]);
    setAvailableIntervals(filteredIntervals);

    if (shiftData.length > 0 && window.validKey) {
      const allowedSlots = shiftData.map(shift => shift.number);
      setFilteredSlots(availableTimeslots.filter(slot => allowedSlots.includes(slot)));
    } else {
      setFilteredSlots(availableTimeslots);
    }
  };

  const filterSlotsByInterval = (interval) => {
    let filteredSlots = [];
    const formattedDate = formatDateToGMT2(selectedDate);
    const timeslots = slotData[formattedDate] || {};
    const availableTimeslots = Object.keys(timeslots)
      .filter((slot) => {
        const isToday = isSameDay(selectedDate, new Date());
        const isSlotValid = timeslots[slot] >= guests;
        if (isToday) {
          const slotTime = slotToDate(slot);
          const currentTimePlus4Hours = new Date();
          currentTimePlus4Hours.setHours(currentTimePlus4Hours.getHours() + shiftLength);
          return isSlotValid && slotTime >= currentTimePlus4Hours;
        }
        return isSlotValid;
      })
      .map(Number);

    const checkConsecutiveSlots = (startSlot) => {
      for (let i = 0; i < shiftLength; i++) {
        if (!availableTimeslots.includes(startSlot + i)) {
          return false;
        }
      }
      return true;
    };

    switch (interval) {
      case 'Morning':
        filteredSlots = availableTimeslots.filter(slot => slot >= 14 && slot < 24 && checkConsecutiveSlots(slot));
        break;
      case 'Afternoon':
        filteredSlots = availableTimeslots.filter(slot => slot >= 24 && slot < 35 && checkConsecutiveSlots(slot));
        break;
      case 'Evening':
        filteredSlots = availableTimeslots.filter(slot => slot >= 35 && slot <= 47 && checkConsecutiveSlots(slot));
        break;
      case 'Night':
        filteredSlots = availableTimeslots.filter(slot => slot >= 1 && slot < 14 && checkConsecutiveSlots(slot));
        break;
      default:
        filteredSlots = availableTimeslots.filter(slot => checkConsecutiveSlots(slot));
    }

    if (shiftData.length > 0) {
      const allowedSlots = shiftData.map(shift => shift.number);
      return filteredSlots.filter(slot => allowedSlots.includes(slot));
    }

    return filteredSlots;
  };

  const formatDateToGMT2 = (date) => {
    const offset = 2 * 60;
    const newDate = new Date(date.getTime() + offset * 60000);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const slotToDate = (slot) => {
    const hours = Math.floor(slot / 2);
    const minutes = (slot % 2) * 30;
    const date = new Date(selectedDate);
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  };

  const handleSlotSelect = (slot) => {
    setLocalSelectedSlot(slot);
    setSelectedSlot(slot);
      const shiftDescription = shiftData.find(shift => shift.number === slot)?.description || '';
    if (shiftDescription) {
      localStorage.setItem('shiftDescription', shiftDescription);
    } else {
      localStorage.removeItem('shiftDescription');
    }
  };

  const handleIntervalSelect = (interval) => {
    setSelectedInterval(interval);
  };

  if (!selectedDate) {
    return (
      <div className="timeslot-buttons">
        <Button className="m-1 timeslot-button">
          {language === 'NL' ? 'Kies een dag' : language === 'FR' ? 'Sélectionnez un jour' : 'Select a day'}
        </Button>
      </div>
    );
  }

  if (!selectedInterval) {
    return (
      <div className="timeslot-buttons">
        {displayedIntervals.map((interval, index) => (
          <Button key={index} className="m-1 timeslot-button" onClick={() => handleIntervalSelect(interval)}>
            {language === 'NL' ? interval === 'Night' ? 'Nacht' : interval === 'Morning' ? 'Ochtend' : interval === 'Afternoon' ? 'Middag' : 'Avond' : language === 'FR' ? interval === 'Night' ? 'Nuit' : interval === 'Morning' ? 'Matin' : interval === 'Afternoon' ? 'Après-midi' : 'Soir' : interval}
          </Button>
        ))}
      </div>
    );
  }

  const slotsToDisplay = shiftData.length > 0 ? filteredSlots : filterSlotsByInterval(selectedInterval);

  if (slotsToDisplay.length === 0) {
    return (
      <div className="timeslot-buttons">
        <Button className="m-1 timeslot-button">
          {language === 'NL' ? 'Kies een andere dag' : language === 'FR' ? 'Sélectionnez un autre jour' : 'Select another day'}
        </Button>
      </div>
    );
  }

  return (
    <div className="timeslot-buttons">
      {slotsToDisplay.map((slot, index) => {
        const shiftDescription = shiftData.find(shift => shift.number === slot)?.description || '';
        return (
          <Button
            key={index}
            variant={localSelectedSlot === slot ? "primary" : "outline-primary"}
            className="m-1 timeslot-button"
            onClick={() => handleSlotSelect(slot)}
          >
            {`${shiftDescription ? `${shiftDescription}` : `${slotToTime(Number(slot))}`}`}
          </Button>
        );
      })}
    </div>
  );
};

const isSameDay = (date1, date2) => {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
};

export default Timeslots;