const slotToTimeTable = {
	1: "00:00",  2: "00:30",
	3: "01:00",  4: "01:30",
	5: "02:00",  6: "02:30",
	7: "03:00",  8: "03:30",
	9: "04:00", 10: "04:30",
	11: "05:00", 12: "05:30",
	13: "06:00", 14: "06:30",
	15: "07:00", 16: "07:30",
	17: "08:00", 18: "08:30",
	19: "09:00", 20: "09:30",
	21: "10:00", 22: "10:30",
	23: "11:00", 24: "11:30",
	25: "12:00", 26: "12:30",
	27: "13:00", 28: "13:30",
	29: "14:00", 30: "14:30",
	31: "15:00", 32: "15:30",
	33: "16:00", 34: "16:30",
	35: "17:00", 36: "17:30",
	37: "18:00", 38: "18:30",
	39: "19:00", 40: "19:30",
	41: "20:00", 42: "20:30",
	43: "21:00", 44: "21:30",
	45: "22:00", 46: "22:30",
	47: "23:00", 48: "23:30",
  };
  
  const timeToSlotTable = {
	"00:00": 1,  "00:30": 2,
	"01:00": 3,  "01:30": 4,
	"02:00": 5,  "02:30": 6,
	"03:00": 7,  "03:30": 8,
	"04:00": 9,  "04:30": 10,
	"05:00": 11, "05:30": 12,
	"06:00": 13, "06:30": 14,
	"07:00": 15, "07:30": 16,
	"08:00": 17, "08:30": 18,
	"09:00": 19, "09:30": 20,
	"10:00": 21, "10:30": 22,
	"11:00": 23, "11:30": 24,
	"12:00": 25, "12:30": 26,
	"13:00": 27, "13:30": 28,
	"14:00": 29, "14:30": 30,
	"15:00": 31, "15:30": 32,
	"16:00": 33, "16:30": 34,
	"17:00": 35, "17:30": 36,
	"18:00": 37, "18:30": 38,
	"19:00": 39, "19:30": 40,
	"20:00": 41, "20:30": 42,
	"21:00": 43, "21:30": 44,
	"22:00": 45, "22:30": 46,
	"23:00": 47, "23:30": 48,
  };
  
  const slotToTime = (slot) => {
	return slotToTimeTable[slot];
  };
  
  const timeToSlot = (time) => {
	return timeToSlotTable[time];
  };
  
  export { slotToTime, timeToSlot };
  