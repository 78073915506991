import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { format } from 'date-fns';
import { nl, fr } from 'date-fns/locale';
import '../../css/ReservationModal.css';
import { slotToTime } from '../../../utils/converters';

const ReservationModal = ({
  showModal,
  setShowModal,
  guests,
  selectedDate,
  selectedSlot,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  handleSubmit,
  handleGuestsClick,
  language,
  selectedMenu, // Receive selectedMenu from props
  extraInfo,
  setExtraInfo
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submission
  const emailDomains = ['@gmail.com', '@hotmail.com', '@telenet.be'];

  const isAllDataFilled = () => {
    const storedFirstName = localStorage.getItem('firstName');
    const storedLastName = localStorage.getItem('lastName');
    const storedEmail = localStorage.getItem('email');
    const storedPhone = localStorage.getItem('phone');

    return storedFirstName && storedFirstName.trim() !== '' &&
           storedLastName && storedLastName.trim() !== '' &&
           storedEmail && storedEmail.trim() !== '' &&
           storedPhone && storedPhone.trim() !== '';
  };

  useEffect(() => {
    if (isAllDataFilled()) {
      const storedFirstName = localStorage.getItem('firstName');
      const storedLastName = localStorage.getItem('lastName');
      const storedEmail = localStorage.getItem('email');
      const storedPhone = localStorage.getItem('phone');

      setFirstName(storedFirstName);
      setLastName(storedLastName);
      setEmail(storedEmail);
      setPhone(storedPhone);
    } else {
      setIsEditing(true);
    }
  }, [setFirstName, setLastName, setEmail, setPhone]);

  useEffect(() => {
    if (firstName.trim() !== '') localStorage.setItem('firstName', firstName);
  }, [firstName]);

  useEffect(() => {
    if (lastName.trim() !== '') localStorage.setItem('lastName', lastName);
  }, [lastName]);

  useEffect(() => {
    if (email.trim() !== '') localStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    if (phone.trim() !== '') localStorage.setItem('phone', phone);
  }, [phone]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!firstName.trim()) newErrors.firstName = language === 'NL' ? 'Voornaam is verplicht' : language === 'FR' ? 'Le prénom est requis' : 'First name is required';
    if (!lastName.trim()) newErrors.lastName = language === 'NL' ? 'Achternaam is verplicht' : language === 'FR' ? 'Le nom est requis' : 'Last name is required';
    if (!email.trim() || !validateEmail(email)) newErrors.email = language === 'NL' ? 'Ongeldig emailadres' : language === 'FR' ? 'Adresse e-mail invalide' : 'Invalid email address';
    if (!phone.trim()) newErrors.phone = language === 'NL' ? 'Telefoonnummer is verplicht' : language === 'FR' ? 'Le numéro de téléphone est requis' : 'Phone number is required';
    if (!agreedTerms) newErrors.agreedTerms = language === 'NL' ? 'U moet akkoord gaan met de voorwaarden' : language === 'FR' ? 'Vous devez accepter les termes' : 'You must agree to the terms';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async () => {
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        await handleSubmit();
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const atPosition = newEmail.indexOf('@');
    if (atPosition === -1) {
      setEmailSuggestions(emailDomains);
    } else {
      setEmailSuggestions([]);
    }
  };

  const handleEmailSuggestionClick = (suggestion) => {
    setEmail((prevEmail) => prevEmail.split('@')[0] + suggestion);
    setEmailSuggestions([]);
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
      <Modal.Title>{language === 'NL' ? 'Reservatie Bewerken' : language === 'FR' ? 'Modifier la Réservation' : 'Edit Reservation'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{language === 'NL' ? 'Datum' : language === 'FR' ? 'Date' : 'Date'}: {selectedDate ? format(selectedDate, 'd MMMM yyyy', { locale: language === 'NL' ? nl : fr }) : ''}</p>
        <p>{language === 'NL' ? 'Tijd' : language === 'FR' ? 'Heure' : 'Time'}: {
          (() => {
            const shiftDescription = window.shift ? window.shift.find(shift => shift.number === Number(selectedSlot))?.description : null;
            return shiftDescription || (selectedSlot !== null ? slotToTime(Number(selectedSlot)) : '');
          })()
        }</p>
        <p onClick={handleGuestsClick} style={{ cursor: 'pointer' }}>{language === 'NL' ? 'Aantal Personen' : language === 'FR' ? 'Nombre de Convives' : 'Number of Guests'}: {guests}</p>
        <Form>
          {isEditing ? (
            <>
              <Form.Group controlId="formFirstName">
                <Form.Label>{language === 'NL' ? 'Voornaam' : language === 'FR' ? 'Prénom' : 'First Name'}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={language === 'NL' ? 'Vul voornaam in' : language === 'FR' ? 'Entrez le prénom' : 'Enter first name'}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  isInvalid={!!errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formLastName" className="mt-2">
                <Form.Label>{language === 'NL' ? 'Achternaam' : language === 'FR' ? 'Nom de Famille' : 'Last Name'}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={language === 'NL' ? 'Vul achternaam in' : language === 'FR' ? 'Entrez le nom de famille' : 'Enter last name'}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  isInvalid={!!errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formEmail" className="mt-2">
                <Form.Label>{language === 'NL' ? 'Email' : language === 'FR' ? 'Email' : 'Email'}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={language === 'NL' ? 'Vul email in' : language === 'FR' ? 'Entrez l\'email' : 'Enter email'}
                  value={email}
                  onChange={handleEmailChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
                {emailSuggestions.length > 0 && (
                  <div className="email-suggestions">
                    {emailSuggestions.map((suggestion) => (
                      <Button
                        key={suggestion}
                        variant="outline-secondary"
                        className="email-suggestion"
                        onClick={() => handleEmailSuggestionClick(suggestion)}
                      >
                        {email.split('@')[0]}{suggestion}
                      </Button>
                    ))}
                  </div>
                )}
              </Form.Group>
              <Form.Group controlId="formPhone" className="mt-2">
                <Form.Label>{language === 'NL' ? 'Telefoon' : language === 'FR' ? 'Téléphone' : 'Phone'}</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder={language === 'NL' ? 'Vul telefoonnummer in' : language === 'FR' ? 'Entrez le numéro de téléphone' : 'Enter phone number'}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  isInvalid={!!errors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formExtraInfo" className="mt-2">
                <Form.Label>{language === 'NL' ? 'Extra Informatie / Allergenen' : language === 'FR' ? 'Informations Supplémentaires / Allergènes' : 'Extra Information / Allergens'}</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={language === 'NL' ? 'Vul extra informatie in' : language === 'FR' ? 'Entrez des informations supplémentaires' : 'Enter extra information'}
                  value={extraInfo}
                  onChange={(e) => setExtraInfo(e.target.value)}
                />
              </Form.Group>
            </>
          ) : (
            <div className="info-block">
              <div className="info-item">
                <span>{language === 'NL' ? 'Voornaam' : language === 'FR' ? 'Prénom' : 'First Name'}: {firstName}</span>
                <span className="edit-icon" onClick={() => setIsEditing(true)}>✎</span>
              </div>
              <div className="info-item">
                <span>{language === 'NL' ? 'Achternaam' : language === 'FR' ? 'Nom de Famille' : 'Last Name'}: {lastName}</span>
                <span className="edit-icon" onClick={() => setIsEditing(true)}>✎</span>
              </div>
              <div className="info-item">
                <span>{language === 'NL' ? 'Email' : language === 'FR' ? 'Email' : 'Email'}: {email}</span>
                <span className="edit-icon" onClick={() => setIsEditing(true)}>✎</span>
              </div>
              <div className="info-item">
                <span>{language === 'NL' ? 'Telefoon' : language === 'FR' ? 'Téléphone' : 'Phone'}: {phone}</span>
                <span className="edit-icon" onClick={() => setIsEditing(true)}>✎</span>
              </div>
              <div className="info-item">
                <span>{language === 'NL' ? 'Extra Informatie' : language === 'FR' ? 'Informations Supplémentaires' : 'Extra Information'}: {extraInfo}</span>
                <span className="edit-icon" onClick={() => setIsEditing(true)}>✎</span>
              </div>
            </div>
          )}
          <Form.Group controlId="formAgreedTerms" className="mt-2">
            <Form.Check
              type="checkbox"
              label={
                <span>
                  {language === 'NL' ? 'Ik ga akkoord met de ' : language === 'FR' ? 'J\'accepte les ' : 'I agree with the '}
                  <a href="https://voorwaarden.reservaties.net" target="_blank" rel="noopener noreferrer">
                    {language === 'NL' ? 'voorwaarden' : language === 'FR' ? 'termes' : 'terms'}
                  </a>
                </span>
              }
              checked={agreedTerms}
              onChange={(e) => {
                setAgreedTerms(e.target.checked);
                setErrors((prevErrors) => ({ ...prevErrors, agreedTerms: '' }));
              }}
              isInvalid={!!errors.agreedTerms}
            />
            <Form.Control.Feedback type="invalid">
              {errors.agreedTerms}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {isSubmitting ? (
          <Spinner animation="border" />
        ) : (
          <>
            {isEditing || !isAllDataFilled() ? (
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                {language === 'NL' ? 'Sluiten' : language === 'FR' ? 'Fermer' : 'Close'}
              </Button>
            ) : (
              <Button variant="secondary" onClick={() => setIsEditing(true)}>
                {language === 'NL' ? 'Bewerken' : language === 'FR' ? 'Modifier' : 'Edit'}
              </Button>
            )}
            <Button variant="primary" onClick={handleFormSubmit}>
              {language === 'NL' ? 'Bevestigen' : language === 'FR' ? 'Confirmer' : 'Confirm'}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ReservationModal;
