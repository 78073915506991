import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import Calendar from '../date-time/Calendar';
import Timeslot from '../date-time/Timeslot';
import DeleteModal from '../modals/DeleteModal';

const ReservationDetails = ({
  language,
  labels,
  setLabels,
  fetchLabels,
  fetchSlotData,
  slotData,
  guests,
  setGuests,
  selectedLabel,
  setSelectedLabel,
  selectedLabelId,
  setSelectedLabelId,
  menuItems,
  setMenuItems,
  fetchMenuItems,
  selectedDate,
  setSelectedDate,
  selectedSlot,
  setSelectedSlot,
  selectedMenu,
  setSelectedMenu,
  maxGuests,
  guestsRef,
  showAlert,
  editToken,  // The edit token is passed from the parent component
  setShowDeletionAlert, // New prop to trigger the deletion alert
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    fetchLabels();
    fetchMenuItems();
  }, []);

  useEffect(() => {
    const today = new Date();
    if (selectedLabelId != null) {
      fetchSlotData(today.getFullYear(), today.getMonth() + 1, selectedLabelId);
    }
  }, [selectedLabelId]);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null);
  };

  const isMonthValid = (ruleset, date) => {
    const monthRule = ruleset.match(/_m[1-9]|_m1[0-2]/);
    if (!monthRule) return true;
    const ruleMonth = parseInt(monthRule[0].slice(2), 10);
    const selectedMonth = date ? date.getMonth() + 1 : 'N/A';
    return ruleMonth === selectedMonth;
  };

  const isDayValid = (ruleset, date) => {
    const dayRules = ruleset.match(/d[1-7]/g);
    if (!dayRules) return true;
    const selectedDay = date ? date.getDay() : 'N/A';
    if (date) {
      return dayRules.some(d => {
        const ruleDay = parseInt(d.slice(1), 10);
        return ruleDay === selectedDay;
      });
    }
    return true;
  };

  const handleGuestChange = (event) => {
    setGuests(Number(event.target.value));
  };

  const handleLabelChange = async (selectedOption) => {
    setSelectedLabel(selectedOption.value);
    setSelectedLabelId(selectedOption.value);
  };

  const handleSlotSelect = (slot, description) => {
    setSelectedSlot(slot);
  };

  const handleMenuChange = (e) => {
    setSelectedMenu(e.target.value);
  };

  const filteredMenuItems = menuItems.filter(item => {
    const rulesMatch = item.display_description.match(/__rules(.*)/);
    if (!rulesMatch) return true;

    const ruleset = rulesMatch[0];
    const monthValid = isMonthValid(ruleset, selectedDate);
    const dayValid = isDayValid(ruleset, selectedDate);

    return monthValid && dayValid;
  }).map(item => ({
    ...item,
    display_description: item.display_description.split('__rules')[0]
  }));

  const handleDelete = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowDeleteModal(false);
    try {
      const response = await fetch(window.baseDomain + `reservations/delete-by-token/${editToken}/`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete reservation');
      }
      console.log('Reservation deleted successfully');
      setShowDeletionAlert(true); // Trigger the alert in the Reservation component
    } catch (error) {
      console.error('Error deleting reservation:', error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const getDeleteModalTitle = () => {
    switch (language) {
      case 'NL':
        return 'Bevestig verwijdering';
      case 'FR':
        return 'Confirmer la suppression';
      default:
        return 'Confirm Deletion';
    }
  };

  const getCancelLabel = () => {
    switch (language) {
      case 'NL':
        return 'Nee, annuleer';
      case 'FR':
        return 'Non, annuler';
      default:
        return 'No, cancel';
    }
  };

  const getConfirmLabel = () => {
    switch (language) {
      case 'NL':
        return 'Ja, verwijderen';
      case 'FR':
        return 'Oui, supprimer';
      default:
        return 'Yes, delete';
    }
  };

  return (
    <>
      <Row className="guests-row justify-content-md-center">
        <Col xs={12} sm={12} md={8} lg={12} xl={8}>
          <Form>
            <Form.Label htmlFor="formNumberOfGuests">
              {language === 'NL' ? 'Aantal Gasten' : language === 'FR' ? 'Nombre de Convives' : 'Number of Guests'}
            </Form.Label>
            <Form.Group controlId="formNumberOfGuests">
              <Form.Control
                as="select"
                value={guests}
                onChange={handleGuestChange}
                ref={guestsRef}
              >
                {Array.from({ length: maxGuests }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {language === 'NL' ? `${i + 1} ${i + 1 > 1 ? 'personen' : 'persoon'}` : language === 'FR' ? `${i + 1} personne${i + 1 > 1 ? 's' : ''}` : `${i + 1} ${i + 1 > 1 ? 'people' : 'person'}`}
                  </option>
                ))}
                {window.ownerPhone && maxGuests && (
                  <option key={maxGuests + 1} value={maxGuests + 1}>
                    {language === 'NL' ? `${maxGuests}+ personen` : language === 'FR' ? `${maxGuests}+ personnes` : `${maxGuests}+ people`}
                  </option>
                )}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
      </Row>

      {!showAlert && (
        <>
          {labels.length > 1 && (
            <Row className="selection-box mt-3 justify-content-md-center align-items-center">
              <Col xs={12} sm={12} md={8} lg={12} xl={8}>
                <Form.Label htmlFor="formReservationType">
                  {language === 'NL' ? 'Selecteer Zitplaats' : language === 'FR' ? 'Sélectionnez le Type de Réservation' : 'Select Reservation Type'}
                </Form.Label>
                <Select
                  options={labels}
                  value={labels.find(option => option.value === selectedLabel)}
                  onChange={handleLabelChange}
                  className="label-selector"
                />
              </Col>
            </Row>
          )}

          {filteredMenuItems.length > 0 && (
            <Row className="selection-box mt-3 justify-content-md-center align-items-center">
              <Col xs={12} sm={12} md={8} lg={12} xl={8}>
                <Form.Label htmlFor="formMenu">
                  {language === 'NL' ? 'Menu' : language === 'FR' ? 'Menu' : 'Menu'}
                </Form.Label>
                <Form.Control
                  as="select"
                  value={selectedMenu}
                  onChange={handleMenuChange}
                >
                  <option value="">{language === 'NL' ? 'Selecteer een menu' : language === 'FR' ? 'Sélectionnez un menu' : 'Select a menu'}</option>
                  {filteredMenuItems.map((menu) => (
                    <option key={menu.id} value={menu.display_description}>{menu.display_description}</option>
                  ))}
                </Form.Control>
              </Col>
            </Row>
          )}

          <Row className="row justify-content-md-center align-items-start">
            <Col xs={12} sm={12} md={8} lg={6} xl={4}>
              <Calendar
                selectedDate={selectedDate}
                handleDateSelect={handleDateSelect}
                slotData={slotData}
                guests={guests}
                language={language}
                fetchSlotData={(year, month) => fetchSlotData(year, month, selectedLabelId)}
                allowedDates={window.allDates}
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={6} xl={4}>
              <Timeslot
                selectedDate={selectedDate}
                slotData={slotData}
                guests={guests}
                setSelectedSlot={handleSlotSelect}
                language={language}
              />
            </Col>
          </Row>
        </>
      )}

      <Row className="mt-4 justify-content-md-center">
        <Col xs={12} sm={12} md={8} lg={12} xl={8}>
          <Button
            style={{ marginTop: "20px", marginBottom: "20px"}}
            variant="danger"
            className="w-100 timeslot-button red-button"
            onClick={handleDelete}
          >
            {language === 'NL' ? 'Verwijder Reservatie' : language === 'FR' ? 'Supprimer la Réservation' : 'Delete Reservation'}
          </Button>
        </Col>
      </Row>

      <DeleteModal
        visible={showDeleteModal}
        title={getDeleteModalTitle()}
        message={language === 'NL' ? 'Weet u zeker dat u deze reservatie wilt verwijderen?' : language === 'FR' ? 'Êtes-vous sûr de vouloir supprimer cette réservation ?' : 'Are you sure you want to delete this reservation?'}
        cancelLabel={getCancelLabel()}
        confirmLabel={getConfirmLabel()}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default ReservationDetails;
