import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Success from './pages/Success';
import Edit from './pages/Edit';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Edit />} />
        <Route path="/success/:language" element={<Success />} />
      </Routes>
    </Router>
  );
}

export default App;
