import React, { useRef, useEffect } from 'react';
import { Container, Row, Col, Card, Alert, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { FaPrint, FaMapMarkerAlt } from 'react-icons/fa';
import '../App.css';
import './css/Success.css';
import { slotToTime } from '../utils/converters';

const Success = () => {
  const { language } = useParams();
  const location = useLocation();
  const { state } = location;
  const reservation = state?.postData || {};
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {language === 'NL'
        ? 'Mateza is opgericht door Thibault Van de Sompele (23), een jonge programmeur die het monopolie in reservatiesystemen wilt doorbreken.'
        : language === 'FR'
          ? 'Mateza a été fondée par Thibault Van de Sompele (23 ans), un jeune programmeur qui souhaite briser le monopole des systèmes de réservation.'
          : 'Mateza was founded by Thibault Van de Sompele (23), a young programmer who wants to break the monopoly in reservation systems.'
      }
    </Tooltip>
  );

  const openMap = () => {
    window.open(window.restaurantMapsUrl, '_blank');
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('shiftDescription');
    };
  }, []);

  const shiftDescription = localStorage.getItem('shiftDescription');
  const timeDisplay = shiftDescription ? shiftDescription : slotToTime(reservation.start_time);

  return (
    <Container className="mt-10">
      <div className="fullscreen-bg"></div>
      <div className="black-overlay"></div>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={12} md={12} lg={8} xl={8} className="text-white">
          <Alert className="alert" variant="success">
            {language === 'NL' ? 'Bedankt voor uw reservatie! We kijken ernaar uit u te verwelkomen.' : language === 'FR' ? 'Merci pour votre réservation ! Nous avons hâte de vous accueillir.' : 'Thank you for your reservation! We look forward to welcoming you.'}
          </Alert>
          <Card className="mt-5 card" ref={componentRef}>
            <Card.Header>
              <Card.Title className='title-header'>
                {language === 'NL' ? `Bevestiging Reservatie op ${reservation.date}` : language === 'FR' ? `Confirmation de Réservation le ${reservation.date}` : `Reservation Confirmation on ${reservation.date}`}
              </Card.Title>
            </Card.Header>
            <Card.Body className='card-body'>
                <p>{language === 'NL' ? 'Naam' : language === 'FR' ? 'Nom' : 'Name'}: {reservation.first_name} {reservation.last_name}</p>
                <p>{language === 'NL' ? 'Datum' : language === 'FR' ? 'Date' : 'Date'}: {reservation.date}</p>
                <p>{language === 'NL' ? 'Tijd' : language === 'FR' ? 'Heure' : 'Time'}: {timeDisplay}</p>
                <p>{language === 'NL' ? 'Aantal personen' : language === 'FR' ? 'Nombre de personnes' : 'Number of people'}: {reservation.guests}</p>
                {reservation.menu && <p>{language === 'NL' ? 'Menu' : language === 'FR' ? 'Menu' : 'Menu'}: {reservation.menu}</p>}
                {reservation.extra_info && <p>{language === 'NL' ? 'Extra informatie' : language === 'FR' ? 'Informations supplémentaires' : 'Extra information'}: {reservation.extra_info}</p>}
            </Card.Body>
          </Card>
          <div className="mt-3">
            <Button variant="primary" onClick={handlePrint} className="me-2">
              <FaPrint className="me-2" /> {language === 'NL' ? 'Print Reservatie' : language === 'FR' ? 'Imprimer Réservation' : 'Print Reservation'}
            </Button>
            <Button variant="secondary" onClick={openMap}>
              <FaMapMarkerAlt className="me-2" /> {language === 'NL' ? 'Toon Kaart' : language === 'FR' ? 'Afficher la Carte' : 'Show Map'}
            </Button>
          </div>
        </Col>
      </Row>
      <div className="mateza-container-success">
        <a className="mateza-link-success" href="https://mateza.be">Mateza 2024</a>
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <span className="info-icon-success"> ⓘ</span>
        </OverlayTrigger>
      </div>
    </Container>
  );
};

export default Success;
