import React from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useState } from 'react';

const ExtraInfo = ({
  language,
  reservationData,
  editToken,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  extraInfo,
  setExtraInfo,
  phone,
  setPhone,
  email,
  setEmail,
  emptyFields // Import the emptyFields prop
}) => {
  const [timeslotName, setTimeslotName] = useState(reservationData?.extra_info?.timeslot_name || '');
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const validateFields = () => {
    const newErrors = {};

    if (!emptyFields.firstName && !firstName) newErrors.firstName = true;
    if (!emptyFields.lastName && !lastName) newErrors.lastName = true;
    if (!emptyFields.phone && !phone) newErrors.phone = true;
    if (!emptyFields.email && !email) newErrors.email = true;

    return newErrors;
  };

  const handleSave = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const updateData = {
      start_time: reservationData.start_time,
      date: reservationData.date,
      guests: reservationData.guests,
      restaurant: reservationData.restaurant,
      table_label: reservationData.table_label,
      extra_info: {
        phone,
        email,
        first_name: firstName,
        last_name: lastName,
        date_added: reservationData.extra_info?.date_added,
        language: reservationData.extra_info?.language,
        extra_info: extraInfo,
        admin_reservation: reservationData.extra_info?.admin_reservation,
        timeslot_name: timeslotName,
      },
    };

    const saveToLocalStorage = () => {
      localStorage.setItem('firstName', firstName);
      localStorage.setItem('lastName', lastName);
      localStorage.setItem('extraInfo', extraInfo);
      localStorage.setItem('phone', phone);
      localStorage.setItem('email', email);
    };

    axios.put(window.baseDomain + `reservations/edit-token/${editToken}/`, updateData)
      .then(response => {
        setSuccessMessage(language === 'NL' ? 'Reservatie succesvol bijgewerkt!' : language === 'FR' ? 'Réservation mise à jour avec succès!' : 'Reservation updated successfully!');
        setErrors({});
        saveToLocalStorage();
      })
      .catch(error => {
        console.error('Error updating reservation:', error.response?.data || error.message);
        setErrors({});
      });
  };

  const handleScrollToReservation = () => {
    const element = document.getElementById('reservationTitle');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="extra-info-container">
      {successMessage && (
        <Alert variant="success">
          {successMessage}
        </Alert>
      )}
      <Form>
        <Form.Group controlId="formFirstName">
          <Form.Label>{language === 'NL' ? 'Voornaam' : language === 'FR' ? 'Prénom' : 'First Name'}</Form.Label>
          <Form.Control
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            isInvalid={errors.firstName}
          />
          <Form.Control.Feedback type="invalid">
            {language === 'NL' ? 'Voornaam is verplicht' : language === 'FR' ? 'Le prénom est requis' : 'First Name is required'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formLastName" className="mt-2">
          <Form.Label>{language === 'NL' ? 'Achternaam' : language === 'FR' ? 'Nom de Famille' : 'Last Name'}</Form.Label>
          <Form.Control
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            isInvalid={errors.lastName}
          />
          <Form.Control.Feedback type="invalid">
            {language === 'NL' ? 'Achternaam is verplicht' : language === 'FR' ? 'Le nom de famille est requis' : 'Last Name is required'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formPhone" className="mt-2">
          <Form.Label>{language === 'NL' ? 'Telefoon' : language === 'FR' ? 'Téléphone' : 'Phone'}</Form.Label>
          <Form.Control
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            isInvalid={errors.phone}
          />
          <Form.Control.Feedback type="invalid">
            {language === 'NL' ? 'Telefoonnummer is verplicht' : language === 'FR' ? 'Le numéro de téléphone est requis' : 'Phone number is required'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formEmail" className="mt-2">
          <Form.Label>{language === 'NL' ? 'E-mail' : language === 'FR' ? 'Courriel' : 'Email'}</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {language === 'NL' ? 'E-mail is verplicht' : language === 'FR' ? 'Le courriel est requis' : 'Email is required'}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formExtraInfo" className="mt-2">
          <Form.Label>{language === 'NL' ? 'Extra Informatie' : language === 'FR' ? 'Informations Supplémentaires' : 'Extra Information'}</Form.Label>
          <Form.Control
            as="textarea"
            value={extraInfo}
            onChange={(e) => setExtraInfo(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" className="mt-4" onClick={handleSave}>
          {language === 'NL' ? 'Wijzigingen Opslaan' : language === 'FR' ? 'Enregistrer les Modifications' : 'Save Changes'}
        </Button>
        <Button variant="secondary" className="mt-4 ms-2" onClick={handleScrollToReservation}>
          {language === 'NL' ? 'Bewerk Overige Informatie' : language === 'FR' ? 'Modifier Autres Informations' : 'Edit Other Info'}
        </Button>
      </Form>
    </div>
  );
};

export default ExtraInfo;
