import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import Select from 'react-select';
import ReservationDetails from '../components/edit/main/BookingDetails';
import ReservationModal from '../components/edit/modals/ReservationModal';
import ExtraInfo from '../components/edit/main/Info';
import { registerLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import fr from 'date-fns/locale/fr';
import { format } from 'date-fns';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { slotToTime } from '../utils/converters';
import './css/ReservationEdit.css';

registerLocale('nl', nl);
registerLocale('fr', fr);

const languageOptions = [
  { value: 'NL', label: 'NL' },
  { value: 'EN', label: 'EN' },
  { value: 'FR', label: 'FR' }
];

const EditView = () => {
  const [searchParams] = useSearchParams();
  const editToken = searchParams.get('edit-token');
  const [language, setLanguage] = useState('NL');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slotData, setSlotData] = useState({});
  const [cachedSlotData, setCachedSlotData] = useState({});
  const [guests, setGuests] = useState(2);
  const [showModal, setShowModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [labels, setLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectedLabelId, setSelectedLabelId] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState('');
  const [extraInfo, setExtraInfo] = useState('');
  const [menuItems, setMenuItems] = useState([]);
  const [reservationData, setReservationData] = useState({});
  const [showDeletionAlert, setShowDeletionAlert] = useState(false); 
  const [isInvalidToken, setIsInvalidToken] = useState(false); 
  const navigate = useNavigate();
  const guestsRef = useRef(null);
  const [isFetchingSlots, setIsFetchingSlots] = useState(false);
  const maxGuests = window.maxGuests || 12;
  const showAlert = guests > maxGuests && window.ownerPhone;
  const [emptyFields, setEmptyFields] = useState({ firstName: false, lastName: false, phone: false, email: false });

  useEffect(() => {
    // Clear the selected menu when the date is changed or when a label without a menu is selected
    if (!selectedDate || !menuItems.length || !slotData[selectedDate]) {
      setSelectedMenu("");
      console.log("Menu empty");
    }
  }, [selectedDate, menuItems, slotData, setSelectedMenu]);

  useEffect(() => {
    if (editToken) {
      axios.get(`${window.baseDomain}reservations/edit-token/${editToken}/`)
        .then(response => {
          const data = response.data;
          if (data && data.restaurant) {
            console.log('Reservation Data:', data);
            window.restaurantNumber = data.restaurant;
            setReservationData(data);
            fetchLabels();
            fetchMenuItems();

            const emptyFields = {
              firstName: !data.extra_info.first_name,
              lastName: !data.extra_info.last_name,
              phone: !data.extra_info.phone,
              email: !data.extra_info.email,
            };

            setEmptyFields(emptyFields);

            setFirstName(data.extra_info.first_name);
            setLastName(data.extra_info.last_name);
            setPhone(data.extra_info.phone);
            setEmail(data.extra_info.email);
            setExtraInfo(data.extra_info.extra_info);
            setGuests(data.guests);

            if (data.guests > window.maxGuests) {
              window.maxGuests = data.guests;
            }
          } else {
            setIsInvalidToken(true);
          }
        })
        .catch(error => {
          console.error('Error fetching reservation data:', error);
          setIsInvalidToken(true);
        });
    } else {
      setIsInvalidToken(true);
    }
  }, [editToken]);

  const fetchSlotData = async (year, month, labelId) => {
    const cacheKey = `${year}-${month}-${labelId}`;
    if (cachedSlotData[cacheKey]) {
      setSlotData(cachedSlotData[cacheKey]);
      return;
    }

    if (isFetchingSlots) return;
    setIsFetchingSlots(true);

    try {
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1;
      const startDay = (year === currentYear && month === currentMonth) ? String(today.getDate()).padStart(2, '0') : '01';
      const startOfMonth = `${year}-${String(month).padStart(2, '0')}-${startDay}`;
      const endOfMonth = `${year}-${String(month).padStart(2, '0')}-${new Date(year, month, 0).getDate()}`;

      let data = {};

      if (window.eventPage) {
        const allDates = window.allDates || [];
        const promises = allDates.map(date =>
          fetch(`${window.baseDomain}restaurants/slot/${window.restaurantNumber}/${date}/${labelId}`)
            .then(response => response.json())
            .then(slotData => {
              data[date] = slotData[date];
            })
        );
        await Promise.all(promises);
      } else {
        const response = await fetch(`${window.baseDomain}restaurants/slots/${window.restaurantNumber}/${startOfMonth}/${endOfMonth}/${labelId}`);
        data = await response.json();
      }

      console.log(data);

      setSlotData((prevSlotData) => ({ ...prevSlotData, ...data }));
      setCachedSlotData((prevCachedData) => ({ ...prevCachedData, [cacheKey]: data }));
    } catch (error) {
      console.error('Error fetching slot data:', error);
    } finally {
      setIsFetchingSlots(false);
    }
  };

  const fetchLabels = async () => {
    try {
      const response = await fetch(`${window.baseDomain}tables/label/${window.restaurantNumber}`);
      const data = await response.json();
      const allLabels = data.map(label => ({
        value: label.id,
        label: label.display_description,
        max_capacity: label.max_capacity
      }));

      let filteredLabels = [];

      if (window.validKey && window.eventPage) {
        filteredLabels = allLabels.filter(label => label.label.endsWith(window.eventName));
      } else {
        filteredLabels = allLabels.filter(label => !label.label.endsWith(window.eventName));
      }

      setLabels(filteredLabels);

      if (filteredLabels.length > 0) {
        const defaultLabel = filteredLabels.reduce((max, label) => (label.max_capacity > max.max_capacity ? label : max), filteredLabels[0]);
        setSelectedLabel(defaultLabel.value);
        setSelectedLabelId(defaultLabel.value);

        console.log("Fetching");
        fetchSlotData(new Date().getFullYear(), new Date().getMonth() + 1, defaultLabel.value);
      }
    } catch (error) {
      console.error('Error fetching labels:', error);
    }
  };

  const fetchMenuItems = async () => {
    try {
      if (window.menu && window.menu.length > 0) {
        setMenuItems(window.menu.map((item, index) => ({
          id: index,
          display_description: item
        })));
      } else {
        const response = await fetch(window.baseDomain + `tables/menu-client/${window.restaurantNumber}/`);
        const data = await response.json();
        setMenuItems(data);
      }
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };

  const handleLanguageChange = (selectedOption) => {
    setLanguage(selectedOption.value);
  };

  const handleSubmit = async () => {
    const shiftDescription = localStorage.getItem('shiftDescription');

    const cleanMenu = selectedMenu.split('__rules')[0];

    const reservationData = {
      start_time: selectedSlot !== null ? selectedSlot : null,
      date: format(selectedDate, 'yyyy-MM-dd'),
      guests,
      restaurant: window.restaurantNumber,
      phone,
      email,
      first_name: firstName,
      last_name: lastName,
      language: language,
      table_label: selectedLabelId,
      menu: cleanMenu,
      extra_info: extraInfo,
      timeslot_name: shiftDescription || (slotToTime(selectedSlot) + "u"),
      renewed_reservation: true,
    };

    console.log(reservationData);

    try {
      const response = await fetch(window.baseDomain + `reservations/delete-by-token/${editToken}/`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Failed to delete reservation');
      }
      console.log('Reservation deleted successfully');
    } catch (error) {
      console.error('Error deleting reservation:', error);
    }

    try {
      const response = await fetch(window.baseDomain + 'reservations/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reservationData)
      });

      if (response.ok) {
        console.log('Reservation submitted successfully');
        setShowModal(false);
        navigate(`/success/${language}`, { state: { postData: reservationData } });
      } else {
        const errorData = await response.json();
        console.error('Failed to submit reservation:', errorData);
      }
    } catch (error) {
      console.error('Error submitting reservation:', error);
    }
  };

  useEffect(() => {
    if (selectedSlot !== null) {
      setShowModal(true);
    }
  }, [selectedSlot]);

  const handleGuestsClick = () => {
    setShowModal(false);
    setTimeout(() => {
      guestsRef.current.focus();
      guestsRef.current.click();
    }, 300);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedSlot(null);
  };

  const backgroundImageStyle = window.background ? { backgroundImage: `url(${window.background})` } : {};

  if (isInvalidToken) {
    return (
      <Container className="reservation-container">
        <Alert variant="danger" className="mt-3 text-center">
          {language === 'NL' ? 'Deze reservatie is verwijderd of hernieuwd. Wilt u een reservatie een tweede keer aanpassen? Bekijk dan uw email voor de laatste nieuwe link.' : language === 'FR' ? 'Cette réservation a été supprimée ou renouvelée. Vous souhaitez modifier une réservation une seconde fois ? Consultez votre e-mail pour le lien le plus récent.' : 'This reservation has been deleted or renewed. Do you want to modify a reservation a second time? Please check your email for the latest link.'}
        </Alert>
      </Container>
    );
  }

  return (
    <div>
      <Row className="justify-content-md-center align-items-center">
        <Col xs={12} sm={12} md={8} lg={12} xl={8} className="text-center">
          <h1 style={{ marginTop: "50px", color: "white", backgroundColor: 'rgba(0, 0, 0, 0.5)', backdropFilter: 'blur(10px)', fontSize: "40px", padding: '10px', borderRadius: '8px', display: 'inline-block' }}>
            {window.title ? window.title : (language === 'NL' ? 'Bewerk Reservatie' : language === 'FR' ? 'Modifier la Réservation' : 'Edit Reservation')}
          </h1>
        </Col>
      </Row>
      <Container className="reservation-container">
      <div className='fullscreen-bg-reservation' style={backgroundImageStyle}></div>
      <div className={`frame ${showAlert ? 'frame-alert' : ''}`}></div>

        {showDeletionAlert ? (
          <Alert variant="success" className="mt-3">
            {language === 'NL' ? 'Reservatie succesvol verwijderd.' : language === 'FR' ? 'Réservation supprimée avec succès.' : 'Reservation successfully deleted.'}
          </Alert>
        ) : (
          <>
            <Row className="justify-content-md-center align-items-center">
              <Col xs={6} sm={9} md={6} lg={10} xl={6}>
                <h1>{window.title ? window.title : (language === 'NL' ? '1. Persoonlijke Info' : language === 'FR' ? '1. Infos Personnelles' : '1. Personal Info')}</h1>
              </Col>
              <Col xs={6} sm={3} md={2} lg={2} xl={2} className="language-selector-col">
                <Select
                  options={languageOptions}
                  defaultValue={languageOptions.find(option => option.value === language)}
                  onChange={handleLanguageChange}
                  className="language-selector"
                />
              </Col>
            </Row>

            <Row className="justify-content-md-center align-items-center">
              <Col xs={12} sm={12} md={8} lg={12} xl={8} className="language-selector-col">
                {showAlert && (
                  <Alert variant="info" className="mt-3">
                    {language === 'NL' ? (
                      <>Voor reserveringen van meer dan {window.maxGuests} personen, gelieve ons telefonisch te contacteren op het nummer <a href={`tel:${window.ownerPhone}`}>{window.ownerPhone}</a>.</>
                    ) : language === 'FR' ? (
                      <>Pour les réservations de plus de {window.maxGuests} personnes, nous vous prions de bien vouloir nous contacter directement par téléphone. Vous pouvez nous joindre au <a href={`tel:${window.ownerPhone}`}>{window.ownerPhone}</a>.</>
                    ) : (
                      <>For reservations exceeding {window.maxGuests} people, we kindly ask you to get in touch with us directly by phone. You can reach us at <a href={`tel:${window.ownerPhone}`}>{window.ownerPhone}</a>.</>
                    )}
                  </Alert>
                )}
              </Col>
            </Row>

            <Row style={{ marginBottom: '30px' }} className="justify-content-md-center">
              <Col xs={12} sm={12} md={8} lg={12} xl={8}>
                <ExtraInfo
                  language={language}
                  reservationData={reservationData}
                  editToken={editToken}
                  firstName={firstName} 
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  phone={phone}
                  setPhone={setPhone}
                  email={email}
                  setEmail={setEmail}
                  extraInfo={extraInfo}
                  setExtraInfo={setExtraInfo}
                  emptyFields={emptyFields} // Pass the empty fields information
                />
              </Col>
            </Row>

            <Row className="justify-content-md-center align-items-center">
              <Col xs={8} sm={8} md={8} lg={12} xl={8}>
                <h1 id="reservationTitle">
                  {window.title ? window.title : (language === 'NL' ? '2. Reservatie' : language === 'FR' ? '2. Réservation' : '2. Reservation')}
                </h1>
              </Col>
            </Row>

            <Row className="justify-content-md-center align-items-center">
              <Col xs={12} sm={12} md={8} lg={12} xl={8}>
                <Alert variant="primary" className="mt-3">
                  {language === 'NL' ? (
                    <>
                      Gebruik de kalender om aantal gasten, menu of tijdslot te wijzigen. Selecteer het gewenste aantal gasten, de dag en het tijdstip om het beschikbare menu te bekijken (indien beschikbaar).
                    </>
                  ) : language === 'FR' ? (
                    <>
                      Sélectionnez le nombre de convives, le jour et l'heure souhaités pour voir le menu disponible (si disponible).
                      <br />
                      Vous pouvez sélectionner un nouveau nombre de convives, un nouveau jour et une nouvelle heure si vous le souhaitez, mais cela supprimera votre créneau précédemment réservé et le remplacera par un nouveau créneau.
                    </>
                  ) : (
                    <>
                      Select the desired number of guests, day, and time to view the available menu (if available).
                      <br />
                      You can select a new number of guests, a new day, and a new time if you desire, but this will delete your previously reserved slot and be replaced with a new slot.
                    </>
                  )}
                </Alert>
              </Col>
            </Row>

            <ReservationDetails
              language={language}
              labels={labels}
              setLabels={setLabels}
              fetchLabels={fetchLabels}
              fetchSlotData={fetchSlotData}
              slotData={slotData}
              guests={guests}
              setGuests={setGuests}
              selectedLabel={selectedLabel}
              setSelectedLabel={setSelectedLabel}
              selectedLabelId={selectedLabelId}
              setSelectedLabelId={setSelectedLabelId}
              menuItems={menuItems}
              setMenuItems={setMenuItems}
              fetchMenuItems={fetchMenuItems}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedSlot={selectedSlot}
              setSelectedSlot={setSelectedSlot}
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
              maxGuests={maxGuests}
              guestsRef={guestsRef}
              showAlert={showAlert}
              editToken={editToken}
              setShowDeletionAlert={setShowDeletionAlert} 
            />

            <ReservationModal
              showModal={showModal}
              setShowModal={handleModalClose}
              guests={guests}
              selectedDate={selectedDate}
              selectedSlot={selectedSlot}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              handleSubmit={handleSubmit}
              handleGuestsClick={handleGuestsClick}
              language={language}
              selectedMenu={selectedMenu}
              extraInfo={extraInfo}
              setExtraInfo={setExtraInfo}
            />
          </>
        )}
      </Container>
    </div>
  );
};

export default EditView;
