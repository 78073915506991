import React from 'react';

function DeleteModal({ visible, title, message, onCancel, onConfirm, cancelLabel, confirmLabel }) {
  if (!visible) return null;

  return (
    <div className="custom-alert">
      <div className="custom-alert-content">
        <h5>{title}</h5>
        <p>{message}</p>
        <div className="custom-alert-actions">
          <button className="btn btn-secondary" onClick={onCancel}>{cancelLabel}</button>
          <button className="btn btn-danger" onClick={onConfirm}>{confirmLabel}</button>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
